import React, { Component } from 'react';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Features extends Component {

    render() {
        let features = this.props.features;
        if(features === undefined) {
            return <></>
        }
        return (
        <div className="about-bg-block ">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className='section-title'>
                            <h1 className="feature-title mb60">{features.title}</h1>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Row>
                            <div className="feature-block" style={{paddingLeft:"45px", paddingRight:"45px"}}>
                            {features.features.map((feature, index) => (
                                    <Col lg={12} md={12} sm={12} xs={12} key={feature.title}>
                                        <div className="feature-content wow bounceInLeft animated" data-wow-delay={`${0.1 * (index + 1)}s`}>
                                            <div className='feature-icon-circle'>
                                                <div className='feature-icon'>
                                                     <i class="fa fa-check"></i>
                                                </div>
                                            </div>
                                            <h3 className="feature-title">{feature.title}</h3>
                                            <p className="feature-text">{feature.text}</p>
                                        </div>
                                    </Col>
                            ))}
                            </div>
                        </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='wow fadeInUp animated' data-wow-delay='0.4s'>
                        <center>
                            <img src={features.image} alt="featured image" className='feature-image'/>
                            {features.link !== undefined?
                            <>
                                <br/>
                                <a href={features.link.url} className='feature-text feature-link'>{features.link.text}</a>
                            </>:null}
                        </center>
                    </Col>
                </Row>
            </Container>
        </div>
        );
    }
}