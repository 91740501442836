import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Section404 extends Component {

    render() {
        return (
            <div className="space-medium bg-light">
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <img src="/img/404.png" alt="" className="img-responsive"/>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className="about-section" style={{textAlign:"center"}}>
                                <h1>404 - Page Not Found</h1>
                                <p>The page you requested could not be found.</p>
                                <Link to="/"><div className="btn btn-primary btn-sm">Go Home</div></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}