import React, { Component } from 'react';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Custom extends Component {

    render() {
        let page = this.props.page;
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='page-header'>
                            <h1 className='page-title'>{page.title}</h1>
                        </Col>
                    </Row>
                </Container>
                <Container style={{marginBottom:"50px"}}>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} dangerouslySetInnerHTML={{__html:page.html}}></Col>
                    </Row>
                </Container>
            </>
        );
    }
}